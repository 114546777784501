import React from 'react';
import BlogArticle from 'components/blog/blogArticle';
import { Post } from 'components/blog/content/styled';
import { authorKalina as author } from 'data/authors';
import img from 'img/blog/tips-for-pm.png';

import img1 from 'img/blog/img1.jpg';
import img2 from 'img/blog/img12.jpg';
import img3 from 'img/blog/img13.jpg';

const Content = () => {
  return (
    <Post>
      <p>
        So you landed a new job as a product manager? Congratulations! No matter if it’s your first
        time in a role, or you just moved to work on another product, you probably already know that
        the coming days are going to be exciting and rather hectic.
      </p>
      <p>
        A survey conducted by the{' '}
        <a
          href="https://pm.280group.com/challenges-in-product-management-survey-results/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          280 group
        </a>{' '}
        suggests that almost half of all the companies do not have a consistent product management
        process. It is more than likely that as a new product manager you’re facing a challenge of
        getting it right and building all the processes almost from scratch.
      </p>
      <p>
        How does one get started as a new product manager then? Well, is there a better way to learn
        than to ask some professionals with experience in the field?
      </p>
      <p>
        We’ve asked a couple of product managers to share their{' '}
        <a
          href="https://productschool.com/blog/product-management-2/5-things-you-wish-you-knew-before-starting-in-product/"
          target="_blank"
          rel="noopener noreferrer"
        >
          best tips for new product
        </a>{' '}
        managers. The results have exceeded our expectations. We’ve got a goldmine of information in
        return, backed by years of hands-on experience in the IT field.
      </p>

      <p>Ready to read advice from real product managers? Let’s dive in!</p>
      <h2 id="plan-with-a-longer-time-frame-in-mind">Plan with a longer time frame in mind</h2>
      <blockquote>
        <p>
          <em>
            Define your work for the coming months using a well-known but customized framework, such
            as the OKRs system. When in doubt and losing focus, a good and prioritized plan (based
            on your OKRs) will help you focus on the most meaningful and important projects.
          </em>
        </p>
        <p>
          <strong>
            Agnieszka Rutkiewicz, Product Manager at{' '}
            <a href="https://www.tagvenue.com/" target="_blank" rel="noopener noreferrer nofollow">
              Tagvenue
            </a>
          </strong>
        </p>
      </blockquote>
      <p>
        <img src={img1} alt="bgphoto" title="Planning" />
      </p>
      <p>
        When you start working in a new environment, be careful not to spread too thin. After you
        research the product, you might want to seize all the opportunities at first.
      </p>
      <p>
        And here’s where you should stop, take a deep breath and think again. You’re going to spot
        plenty of interesting things, but you’ll never be able to do them all at once. Also, Rome
        wasn’t built in a day – and so won’t be your product.
      </p>
      <p>
        This is why <strong>prioritization</strong> should be your priority.
      </p>
      <p>
        We know it doesn’t always come easy. With clients requesting new features all the time,
        choosing the most important ones is harder than it seems. Experienced product managers are
        well aware of that. Another product manager we talked to, Mateusz Miodek, pointed out that
        it’s better not to take feature suggestions too literally. Of course, listening to
        customers’ needs and their feedback should be one of the main pillars of your work, but it
        doesn’t mean that you’ve got to be at their beck and call.
      </p>
      <p>
        In order to succeed, you need to make every{' '}
        <a
          href="https://www.atlassian.com/agile/project-management/user-stories"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          user story
        </a>{' '}
        a part of a larger product development strategy. Apart from incoming requests, you also have
        a product roadmap to stick to. Unless the issue is burning, it works much better if you
        incorporate new projects into your strategy rather than jump into them right away.
      </p>
      <p>
        So, how do you make it work? As Agnieszka Rutkiewicz, the product manager of{' '}
        <a href="https://www.tagvenue.com/" target="_blank" rel="noopener noreferrer nofollow">
          Tagvenue
        </a>
        , pointed out, you can stick to one of the widely used frameworks, such as{' '}
        <a
          href="https://www.whatmatters.com/faqs/okr-meaning-definition-example"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          OKRs
        </a>
        . If you’re not yet familiar with the term, it stands for Objectives and Key Results. These
        can be set for the whole product, for a particular team, and for individual employees as
        well.
      </p>
      <h2 id="know-when-to-say-no">Know when to say no</h2>
      <blockquote>
        <p>
          <em>
            As a new product manager, you are bound to get overwhelmed easily. Don&#39;t let it pull
            you down. Focus on doing one thing right rather than doing multiple things poorly.
          </em>
        </p>
        <p>
          <strong>
            Tushar Jain, Founder and Product Manager at{' '}
            <a href="https://onpagechamp.com/" target="_blank" rel="noopener noreferrer nofollow">
              OnPage Champ
            </a>
          </strong>
        </p>
        <p>
          <em>
            One of the tips that you’re going to find in every book and in every podcast on product
            management is <strong>to be assertive</strong>. You’re just one person and you can’t do
            the job of other professionals, as well as you can’t fulfil every wish of every client.
          </em>
        </p>
        <p>
          <strong>
            Aleksandra Klusinska, Technical Product Manager at{' '}
            <a href="https://mapp.com/" target="_blank" rel="noopener noreferrer nofollow">
              Mapp Digital
            </a>
          </strong>
        </p>
      </blockquote>
      <p>
        <img src={img2} alt="plan" title="meet" />
      </p>
      <p>
        Here it comes: the feeling of responsibility. As a product manager, you should be getting
        things done, right?
      </p>
      <p>
        Yes and no. You’re supposed to make sure that everything is on track and you’re the one
        responsible for the outcome, but it doesn’t mean that you need to do every single thing.
      </p>
      <p>
        One of the most important qualities of a good product manager is the ability to delegate
        tasks. Not only is it going to make the process more effective, but also your team mates
        will feel that you trust them to get the job done.
      </p>
      <h2 id="start-from-the-small-things">Start from the small things</h2>
      <blockquote>
        <p>
          <em>
            It&#39;s best to start your journey by identifying the quick wins from your priority
            list and focusing your initial effort on them. That builds both your confidence and
            understanding of the product.
          </em>
        </p>
        <p>
          <strong>Tushar Jain, Founder and Product Manager at OnPage Champ</strong>
        </p>
        <p>
          <em>
            Always build the smallest thing that will allow you to test the hypothesis. You can use
            proven and tested methods, such as the Wizard of Oz prototype or fake door product
            testing.
          </em>
        </p>
        <p>
          <strong>
            Mateusz Miodek, Product Manager at{' '}
            <a
              href="https://www.datafeedwatch.com/"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              DataFeedWatch
            </a>
          </strong>
        </p>
      </blockquote>
      <p>
        Prioritizing and planning to achieve bigger goals doesn’t mean you should skip the
        low-hanging fruit entirely. <strong>Quick wins</strong> will help you see what works and
        which directions are worth pursuing. They also have a psychological effect: when your team
        sees that what they do actually works, they will instantly become more motivated.
      </p>
      <p>
        The small steps principle applies to testing as well. When you’ve got a hypothesis in mind,
        you don’t need a fully developed product to test it, cause let’s be honest, who’s got
        resources for that? Sticking to{' '}
        <a
          href="https://www.interaction-design.org/literature/article/a-simple-introduction-to-lean-ux"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          lean
        </a>{' '}
        and{' '}
        <a
          href="https://www.agilealliance.org/agile101/12-principles-behind-the-agile-manifesto/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          agile
        </a>{' '}
        principles instead is a more efficient way.
      </p>
      <p>
        This approach will help you save so much time, energy and, eventually, money. When you test
        your ideas in a very early phase, you can decide if they’re worth pursuing at all. As
        Mateusz of DataFeedWatch mentioned in his quote, there are many methods that will help you
        implement this kind of testing in your day-to-day work.
      </p>
      <p>
        You don’t even need your customers to start – and even more, you don’t even need a computer!
        For instance, the <strong>WOZ (Wizard of Oz)</strong> methodology involves creating a lo-fi
        prototype, which can even be drawn on paper. Then, the product team members use{' '}
        <strong>role playing</strong> to see how the users would interact with the product. Of
        course, it isn’t perfect, but it’s more than enough to discover main issues at a very early
        stage.
      </p>
      <figure>
        <img src={img3} alt="plan" title="planning" />
        <figcaption>It really doesn’t take much to conduct a basic Wizard of Oz test</figcaption>
      </figure>

      <p>
        Another interesting approach is the <strong>fake door test</strong>. It’s a simple idea –
        you just need to enable users to choose an option that doesn’t exist yet. If you see that
        they click on it, it probably means that they would use if it was available.
      </p>
      <h2 id="get-to-know-your-customers">Get to know your customers</h2>
      <blockquote>
        <p>
          <em>
            If you’re not sure about something, don’t hesitate to talk to other product managers or{' '}
            <strong>customer-facing teams</strong>.
          </em>
        </p>
        <p>
          <strong>
            Aleksandra Klusinska, Technical Product Manager at{' '}
            <a href="https://mapp.com/" target="_blank" rel="noopener noreferrer nofollow">
              Mapp Digital
            </a>
          </strong>
        </p>
        <p>
          <em>
            We have discovered that <strong>talking to our users</strong> and validating our ideas
            at an early stage of a project helped us improve the final result. Instead of designing
            a full solution, get your customers on a call and ask them for feedback using a
            low-fidelity prototype.
          </em>
        </p>
        <p>
          <strong>Agnieszka Rutkiewicz, Product Manager at Tagvenue</strong>
        </p>
      </blockquote>
      <p>
        It may seem obvious, but we really can’t stress this enough. Skip the guesswork and focus on
        your customers instead.
      </p>
      <p>
        You can start from watching{' '}
        <a href="/" target="_blank" rel="noopener noreferrer">
          session recordings
        </a>
        . This alone will help you see how users interact with your product, but there is much more
        you can do. If you’re lacking insight and you need it right away, try talking to your{' '}
        <strong>sales or customer service teams</strong>. They know exactly who’s using your product
        and they have tons of knowledge on the users’ habits and behavior.
      </p>
      <p>
        Above all, you should do your best to interact with your users directly. There are many
        tools to make that happen, from short surveys to face-to-face user tests, where you can see
        exactly how people use your product in real-time.
      </p>
      <h2 id="develop-a-relationship-with-software-developers">
        Develop a relationship with software developers
      </h2>
      <blockquote>
        <p>
          <em>
            The product team usually consists of UX professionals, a product manager and a couple of
            engineers. Make your team work together to solve the real customers’ issues. Although UX
            and product people focus on the &#39;discovery&#39; stage of the product, and the
            engineers mostly care about the &#39;delivery&#39; stage, the two groups should work as
            close as possible from the very beginning.
          </em>
        </p>
        <p>
          <strong>Agnieszka Rutkiewicz, Product Manager at Tagvenue</strong>
        </p>
        <p>
          <em>
            Always treat your developers like partners, not contractors. Be sure to involve them in
            the prototyping phase.
          </em>
        </p>
        <p>
          <strong>Mateusz Miodek, Product Manager at DataFeedWatch</strong>
        </p>
      </blockquote>
      <p>
        Last but not least, don’t forget about your team. You’re working with talented people, and
        you’re working together. Delegating tasks is essential for optimal performance, but it is
        also essential to keep everyone in the loop.
      </p>
      <p>
        What does it mean for your product team? Don’t treat your engineers as a separate entity.
        You should be aware of each other’s potential and limitations from the very beginning.
        Sometimes both parties are not even sure what the other team is doing. Closer collaboration
        is sure to prove beneficial for both developers and product people.
      </p>
      <h2 id="key-takeaways-for-new-product-managers">Key takeaways for new product managers</h2>
      <p>
        If we had to come up with a single piece of advice for new product managers, it would be to{' '}
        <strong>stay curious</strong>. The world of digital products is developing fast, with new
        technologies, methodologies and opportunities emerging all the time. The key is to never
        stop learning and read insights from other, more experienced product managers. We hope this
        article has been a source of inspiration for you!
      </p>
    </Post>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'Tips for new product managers (with expert quotes)',
  url: '/blog/tips-for-new-product-managers-with-expert-quotes/',
  description: `Just started a new job as a product manager? Read these tips from top experts
  in the product management field and learn how to succeed!`,
  author,
  img,
  imgSocial: img,
  date: '2019-12-19',
  category: '',
  group: 'Research',
  timeToRead: 7,
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    img={frontmatter.img}
    imgSocial={process.env.GATSBY_HOST + frontmatter.img}
    group={frontmatter.group}
    timeToRead={frontmatter.timeToRead}
  />
);
